import React, { useState, useEffect } from 'react';
import { Modal, Button, Spinner, Alert } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

const ajaxRequest = async (url, method = 'GET', data) => {
  const options = {
    method,
    headers: { 'Content-Type': 'application/json' },
  };
  if (data) {
    options.body = JSON.stringify(data);
  }

  const response = await fetch(url, options);
  if (!response.ok) {
    throw new Error(`Erreur HTTP ! statut : ${response.status}`);
  }
  return response.json();
};

const CourseItem = ({ course, onClick }) => {
  return (
    <li
      className={`course-item course-item-${course.reserved ? 'reserved' : 'available'}`}
      onClick={() => !course.reserved && onClick(course)}
      style={{
        backgroundColor: course.reserved ? '#ffebee' : '#e8f5e9',
        borderLeft: course.reserved ? '5px solid #f44336' : '5px solid #4caf50',
        cursor: course.reserved ? 'not-allowed' : 'pointer'
      }}
    >
      <h3>{course.name}</h3>
      <p>Date : {course.date}</p>
      <p>Durée : {course.duration}</p>
      <p>Sujet MF1 IdF : {course.subject}</p>
      <p>Date validation cours : {course.validationDate}</p>
      <p className="status">{course.reserved ? 'Déjà réservé' : 'Disponible'}</p>
    </li>
  );
};

const CourseReservation = () => {
  const defaultCourses = [
    { name: "Flottabilité, compressibilité", date: "30/11/2024", support: "Salle", duration: "1h", subject: "69;61", validationDate: "12/11/2024", reserved: false },
    { name: "Pressions partielles, dissolution de l'azote", date: "30/11/2024", support: "Salle", duration: "1h", subject: "70;71", validationDate: "18/11/2024", reserved: false },
    { name: "Optique, accoustique", date: "30/11/2024", support: "Salle", duration: "1h", subject: "72", validationDate: "19/11/2024", reserved: false },
    { name: "Anatomie de l'oreille + accidents de l'oreille (vertiges, …)", date: "11/12/2024", support: "Visio", duration: "2h", subject: "75", validationDate: "26/11/2024", reserved: false },
    { name: "Sytème ventilatoire", date: "11/01/2025", support: "Salle", duration: "1h", subject: "73", validationDate: "09/12/2024", reserved: false },
    { name: "Système circulatoire", date: "11/01/2025", support: "Salle", duration: "1h", subject: "74", validationDate: "12/12/2024", reserved: false },
    { name: "Echanges gazeux", date: "11/01/2025", support: "Salle", duration: "1h", subject: "76", validationDate: "16/12/2024", reserved: false },
    { name: "Barotraumatismes, OPI", date: "29/01/2025", support: "Visio", duration: "1h", subject: "62;68", validationDate: "14/01/2025", reserved: false },
    { name: "Essoufflement, narcose, accidents biochimiques", date: "29/01/2025", support: "Visio", duration: "1h", subject: "63;64", validationDate: "16/01/2025", reserved: false },
    { name: "Froid, accidents d'apnée", date: "05/02/2025", support: "Visio", duration: "1h", subject: "66;67", validationDate: "20/01/2025", reserved: false },
    { name: "Les ADD", date: "05/03/2025", support: "Visio", duration: "2h", subject: "65", validationDate: "11/02/2025", reserved: false },
    { name: "Les modèles de décompression", date: "19/03/2025", support: "Visio", duration: "2h", subject: "NA", validationDate: "04/03/2025", reserved: false },
    { name: "Tables", date: "29/03/2025", support: "Salle", duration: "1h", subject: "60", validationDate: "04/03/2025", reserved: false },
    { name: "Ordinateurs", date: "29/03/2025", support: "Salle", duration: "1h", subject: "60", validationDate: "04/03/2025", reserved: false },
    { name: "Procédures de décompression", date: "29/03/2025", support: "Salle", duration: "1h", subject: "60", validationDate: "04/03/2025", reserved: false },
  ];

  const [courses, setCourses] = useState(defaultCourses);
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    tutorName: '',
    selectedCourse: '',
    selectedDate: ''
  });
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [confirmationMessage, setConfirmationMessage] = useState('');
  const [confirmationStatus, setConfirmationStatus] = useState('');
  const [globalMessage, setGlobalMessage] = useState('');
  const [globalStatus, setGlobalStatus] = useState('');

  useEffect(() => {
    const fetchCourses = async () => {
      try {
        const url = `https://asb95.fr/reservation/enregistrer_reservation.php?nocache=${new Date().getTime()}`;
        const data = await ajaxRequest(url);
        if (data && data.courses) {
          const updatedCourses = defaultCourses.map(defaultCourse => {
            const serverCourse = data.courses.find(c => c.name === defaultCourse.name);
            return serverCourse ? { ...defaultCourse, reserved: serverCourse.reserved } : defaultCourse;
          });
          setCourses(updatedCourses);
        } else {
          throw new Error('Données de cours invalides');
        }
      } catch (error) {
        console.error('Erreur de chargement des cours :', error);
        setGlobalMessage('Erreur lors du chargement des cours. Utilisation des données par défaut.');
        setGlobalStatus('warning');
      }
    };
    fetchCourses();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleCloseModal = () => {
    setFormData({
      firstName: '',
      lastName: '',
      email: '',
      tutorName: '',
      selectedCourse: '',
      selectedDate: ''
    });
    setShowModal(false);
    setConfirmationMessage('');
    setConfirmationStatus('');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setConfirmationMessage('');
    setConfirmationStatus('');

    const { firstName, lastName, email, tutorName, selectedCourse } = formData;
    if (!firstName || !lastName || !email || !tutorName || !selectedCourse) {
      setLoading(false);
      setConfirmationMessage('Tous les champs sont obligatoires pour réserver un cours.');
      setConfirmationStatus('danger');
      return;
    }

    try {
      const url = `https://asb95.fr/reservation/enregistrer_reservation.php?nocache=${new Date().getTime()}`;
      const dataToSend = {
        firstName,
        lastName,
        email,
        tutorName,
        selectedCourse,
        courseDate: formData.selectedDate,
        validationDate: courses.find(c => c.name === selectedCourse)?.validationDate || ''
      };
      const data = await ajaxRequest(url, 'POST', dataToSend);
      setLoading(false);
      if (data.success) {
        setConfirmationMessage('Réservation effectuée avec succès. Un email de confirmation vous a été envoyé.');
        setConfirmationStatus('success');
        setCourses(prevCourses => prevCourses.map(course =>
          course.name === selectedCourse ? { ...course, reserved: true } : course
        ));
      } else {
        if (data.errorType === 'EMAIL_LIMIT_REACHED') {
          setConfirmationMessage('Vous avez déjà réservé deux cours avec cette adresse email. Limite atteinte.');
        } else {
          setConfirmationMessage(data.message || 'Erreur lors de la réservation. Veuillez réessayer.');
        }
        setConfirmationStatus('danger');
      }
    } catch (error) {
      setLoading(false);
      console.error('Erreur lors de la soumission :', error);
      setConfirmationMessage(`Erreur lors de la soumission de la réservation : ${error.message}`);
      setConfirmationStatus('danger');
    }
  };

  const handleCourseClick = (course) => {
    if (!course.reserved) {
      setFormData({
        ...formData,
        selectedCourse: course.name,
        selectedDate: course.date
      });
      setShowModal(true);
    }
  };

  const renderCourseList = (courseList, title) => (
    <div className="course-column">
      <h2 className="text-xl mb-4">{title}</h2>
      <ul>
        {courseList.map((course) => (
          <CourseItem key={course.name} course={course} onClick={handleCourseClick} />
        ))}
      </ul>
    </div>
  );

  const classroomCourses = courses.filter(course => course.support === "Salle");
  const videoCourses = courses.filter(course => course.support === "Visio");

  return (
    <div className="p-4 max-w-7xl mx-auto section">
      {globalMessage && (
        <Alert variant={globalStatus} className="mb-3">
          {globalMessage}
        </Alert>
      )}

      <figure className="wp-block-image alignright size-full">
        <img 
          src="https://asb95.fr/dev/wp-content/uploads/2024/08/cropped-Val-dOise-95-FFESSM-Logo-quadri.png" 
          alt="Logo Val d'Oise 95 FFESSM" 
          className="wp-image-103"
        />
      </figure>
      
      <div className="title-container">
        <h1 className="wp-block-heading">Cours de théorie N4</h1>
        <h2 className="wp-block-heading">Codep95 - saison 2024/2025</h2>
      </div>
      
      <p>
        Comme chaque année, nous donnons la possibilité à des stagiaires MF1 du département de donner un ou deux cours aux prépa N4 de la saison. 
        Ils ont des durées variables, vous pourrez les faire en visio ou en salle et ils sont bien entendu en lien avec les sujets de théorie de votre examen si vous décidez de la passer avec l'Île-de-France.
        Nous vous demandons de travailler votre cours avec votre tuteur et de nous le présenter à la date de validation indiquée ci-dessous.
      </p>
      <p>
        Vous trouverez ci-dessous la liste des cours disponibles ainsi que leur statut actuel. Cliquez sur un cours pour réserver.
      </p>

      <div className="course-list-container">
        {renderCourseList(classroomCourses, "Cours en salle")}
        {renderCourseList(videoCourses, "Cours en visio")}
      </div>

      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Réserver le cours</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {confirmationMessage && (
            <Alert variant={confirmationStatus} className="mb-3">
              {confirmationMessage}
            </Alert>
          )}
          <form onSubmit={handleSubmit} className="popup-form">
            <div className="form-group">
              <label className="block mb-1">Prénom :</label>
              <input
                type="text"
                name="firstName"
                value={formData.firstName}
                onChange={handleChange}
                required
                className="w-full p-2 border rounded"
              />
            </div>
            <div className="form-group">
              <label className="block mb-1">Nom :</label>
              <input
                type="text"
                name="lastName"
                value={formData.lastName}
                onChange={handleChange}
                required
                className="w-full p-2 border rounded"
              />
            </div>
            <div className="form-group">
              <label className="block mb-1">Email :</label>
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
                className="w-full p-2 border rounded"
              />
            </div>
            <div className="form-group">
              <label className="block mb-1">Nom du tuteur :</label>
              <input
                type="text"
                name="tutorName"
                value={formData.tutorName}
                onChange={handleChange}
                required
                className="w-full p-2 border rounded"
              />
            </div>
            <div className="form-group">
              <label className="block mb-1">Cours sélectionné :</label>
              <input
                type="text"
                name="selectedCourse"
                value={formData.selectedCourse}
                readOnly
                className="w-full p-2 border rounded"
              />
            </div>
            <Button variant="primary" type="submit" disabled={loading} className="mt-3 w-100">
              {loading ? <Spinner animation="border" size="sm" /> : 'Réserver'}
            </Button>
          </form>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default CourseReservation;
